import React from "react"
import useMediaQuery from "../../../hooks/useMediaQuery"

interface V8Props {
  Title: string
  Content: string
  IMGSrc: string
  ButtonText: string
}

const V8 = ({ Title, Content, IMGSrc, ButtonText }: V8Props) => {
  const isDesktop = useMediaQuery("(min-width: 1400px)")

  return (
    <div>
      {isDesktop ? (
        <div className="flex flex-row justify-center items-center h-auto mb-4">
          <div className="flex flex-row items-center justify-between w-[90%] lg:w-[65%] xl:w-1/2">
            <div className="w-[60%] bg-blue-400 aspect-[5/4] rounded-tr-[10%] rounded-bl-[10%]">
              {IMGSrc}
            </div>
            <div className="w-[70%] flex flex-row justify-center items-end aspect-[5/4] text-IntermetaDarkPurple">
              <div className="w-[90%] aspect-[5/4] flex flex-col justify-start relative top-10 right-14">
                <div className="font-bold text-3xl">{Title}</div>
                <div className="mt-2  text-lg w-[105%]">{Content}</div>
                <div className="border-2 border-IntermetaDarkPurple text-IntermetaDarkPurple mt-8 aspect-[6/1] w-[70%] flex items-center justify-center text-lg font-semibold">
                  {ButtonText}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-row justify-center items-center h-auto my-4 py-6">
          <div className="flex flex-row items-center justify-between w-[90%] lg:w-[65%] xl:w-1/2">
            <div className="w-[50%] h-56 bg-blue-400 aspect-[5/4] rounded-tr-[10%] rounded-bl-[10%]">
              {IMGSrc}
            </div>
            <div className="w-[100%] flex flex-row justify-center items-center aspect-[5/4] text-IntermetaDarkPurple">
              <div className="w-[100%] aspect-[5/4] flex flex-col justify-center relative right-8">
                <div className="font-bold text-2xl">{Title}</div>
                <div className="my-6 text-sm">{Content}</div>
                <div className="border-2 border-IntermetaDarkPurple text-IntermetaDarkPurple mt-2 aspect-[6/1] w-full md:w-[70%] p-2 flex items-center justify-center text-md">
                  {ButtonText}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default V8
