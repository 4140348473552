import React from "react"
import Footer from "../components/Footer/Footer"
import ContentV8 from "../components/ContentV/ContentV8/ContentV8"
import ContentV8r from "../components/ContentV/ContentV8/ContentV8r"
import Header from "../components/Headers/Header"
import Navbar from "../components/Navbar/Navbar"

import Background from "../images/investorsBackground.png"
import BackgroundMobile from "../images/investorsBackgroundM.png"

const InvestorsPage = () => {
  return (
    <div>
      <Navbar />
      <Header
        title="investors"
        background={Background}
        backgroundMobile={BackgroundMobile}
      />
      <div className="bg-white w-[100%] h-auto">
        <ContentV8
          Title="Title 1"
          Content="PAR 1: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod.  "
          IMGSrc="Img"
          ButtonText="Contact Us"
        />
        <ContentV8r
          Title="Title 2"
          Content="PAR 1: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod."
          IMGSrc="Img"
          ButtonText="Contact Us"
        />
      </div>
      <Footer />
    </div>
  )
}

export default InvestorsPage
